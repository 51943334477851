.carousel {
    padding: 0 !important;
}

.carousel .rec-slider-container {
    margin: 0;
}

.carousel-card .card {
    height: 100%;
}

.carousel .rec-item-wrapper {
    height: 100%;
    width: 20px !important;
}


.slider-container {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 0;
}
.slider-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .slider-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }


.slider-card {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 300px;
    height: 200px;
    border-radius: 10px;
    margin-left: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.carousel-sides .rec{
    min-height: 120px !important;
    // height: 289px;
}

.rec-item-wrapper,.slider2-card{
    min-height: 289px !important;
    height: 100%;
}
@media (min-width: 768px) {
    .carousel-sides .rec{
        min-height: 289px !important;
        height: 289px;
    }
}
@media screen and (max-width: 600px) {  
    .carousel-sides .rec{
        height: 500px;
    }
}


 
.container-carousel{
    p{
        font-size: 21px;
        text-align: center;
        margin-bottom: 0;
    }
}
@media screen and (max-width: 600px) {  
    .container-carousel .slick-slide{
        min-width: 80px !important;
    }
}
.slick-slider .slick-track, .slick-slider .slick-list { direction: ltr; }

.slick-track{
    display: flex;
    align-items: center;
}
.slick-slide{
    margin-right: 15px;
    margin-left: 15px;
}

.partners .slick-slide img{
    max-width: 180px !important;
}
.rec-arrow-left{
height: 100% !important;
border-radius: 0 !important;
color: #fff !important;
width: 70px !important;
padding-right: 1rem;
transform: scaleX(1);
background-color: transparent !important;
background: linear-gradient(270deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%) !important;
}

.rec-arrow-right{
    height: 100% !important;
    border-radius: 0 !important;
    width: 70px !important;
    color: #fff !important;
    padding-right: 1rem;
    transform: scaleX(1);
    background-color: transparent !important;
    background: linear-gradient(90deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%) !important;
    }