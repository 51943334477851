.headerBurgerIcon {
    :global {
        .fa{
            padding: 0;
            font-size: 48px;
            color: #dfb778;
        }
    }
}

.navContainer {
    z-index: 1;
    height: 120px;
    background: transparent !important;
    @media screen and (max-width: 600px) {  
        height: 75px;
   }
    @media screen and (max-width: 2000px) {  
        height: 80px;
   }
}

.navColor {
    background-color: #253342 !important;
}

.blogSoon {
    background-color:#E6CA9A;
    border-radius: 10px;
    padding: 2px 12px;
    font-size: 15px;
    margin: 0 5px 100%;
    color: black    ;
    font-weight: bold;
    position: absolute;
}