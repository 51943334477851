
/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

.accordion__section .fa-minus::before{
    background-color: #BB9266 !important;
    border-radius:50%;
    padding: 4px 6px;
}
.accordion__section .fa-plus::before{
    background-color: #fff !important;
    border-radius:50%;
    padding: 4px 6px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  color: #fff;
  cursor: pointer;
  padding: 25px 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  transition: background-color 0.3s ease;
}
.accordion:focus {
  outline: 0;
  outline: none;
}


/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: #59636F;
}

/* Style the accordion content title */
.accordion__title {
  font-weight: 600;
  font-size: 17px;
  text-align: right;
  margin-bottom: 0;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}


/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: #59636F;
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-weight: 400;
  padding: 25px 18px;
}
