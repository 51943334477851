.custom-list-bullets {
    padding-right: 1rem;
    li{
        position: relative;
        margin-bottom: 0.8rem;
    &:before {
        content:"";
        position: absolute;
        right: -18px;
        top: 7px;
        height: 10px;
        width: 10px;
        border-radius: 5px;
        background-color: #FED766;
    }
}
}
.custom_margin{
    margin: 20px 0;
}
.custom_li{

    font-family: 'Dubai-Bold', sans-serif;
    font-weight: bold;
    font-size: 24px;
    transition: all 0.2s linear;
    &:hover{
    // font-size: 26px;
    border-bottom: 3px solid goldenrod;
    }

}
