.carousel{
    background: rgba(222,222,222,.1);
    margin: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 990px) {
        flex-direction: column;
    }

    &__image{
        width: 100px;
        height: 120px;
    }

    &__imageWrapper{
        margin: 1rem;
    }

    .textWrapper{
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 50%;

        @media (max-width: 990px) {
         height: none;
        }

        &__title{
            align-items: center;
            margin:0 0 1rem;
            @media (max-width: 990px) {
                margin:0 1rem 1rem;
                font-size: 12px;
            }
        }
    
        @media (max-width: 990px) {
            flex-direction: column;
            align-items: center;
            width: 100%;

            
            &__name{
                font-weight: bold;
                color: white;
                margin-top: .5rem;
            }
            &__desc{
                margin:0 2rem 0;
            }
            &__story{
                margin: 0 1rem 1rem;
            }
        }
    }

}


.carouselBackgroundRight {
    background: linear-gradient(270deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%) !important;
}
.carouselBackgroundLeft {
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.05) 100%) !important;
}