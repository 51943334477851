.comment-box > .header {
    height: 30px;
    line-height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #eeeeee;
    overflow: hidden; 
}
    .comment-box > .header .left {
      float: right; 
    }
      .comment-box > .header .left h2 {
        padding-right: 10px;
        font-size: 20px;
        float: right; }
      .comment-box > .header .left small {
        font-size: 90%;
        padding-right: 5px; }
        @media (max-width: 480px) {
          .comment-box > .header .left small {
            padding-right: 2px; } }
      .comment-box > .header .left .moderation-plus-settings {
        float: right; }
        .comment-box > .header .left .moderation-plus-settings button span {
          position: relative;
          top: 1px; }
    .comment-box > .header .right {
      float: left; }
      .comment-box > .header .right > span {
        padding-left: 10px;
        padding-right: 10px; }
      @media (max-width: 480px) {
        .comment-box > .header .right {
          font-size: 10px;
          margin-top: 1px; }
          .comment-box > .header .right > span {
            padding-left: 3px;
            padding-right: 3px; } }
  
  .comment-box > .body {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 25px; }
  
  .comment-box .disabled-comments {
    text-align: center; }
  
  
  