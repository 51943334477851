.card{
    background: transparent;
    .card-title{
        color: #fff
    }
}

.custom-card-img-top{
    position:relative;
    z-index:3
}
.custom-card-img-top img{z-index:0}

.custom-card-img-top:after {
    content: "";
    position:absolute;
    width:200px;
    bottom:-10px;
    right:0px;
    height:55px;
    z-index:-1;
    background: linear-gradient(270deg, #C7AB86 0%, rgba(199,171,134,0) 100%);
}

.scale {
    transition: transform 0.3s ease-in-out;
    &:hover{
        transform:scaleX(1.05);
    }
}

.padding-animation {
    transition: padding 0.5s ease-in-out;
    &:hover{
        padding-bottom:1rem;
    }
}