.btn-lg, .btn-group-lg > .btn {
    line-height: 1.9;
}

/* Bounce To Top */
.hvr-bounce-to-top {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
  .hvr-bounce-to-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2098D1;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-bounce-to-top:hover, .hvr-bounce-to-top:focus, .hvr-bounce-to-top:active {
    color: white;
  }
  .hvr-bounce-to-top:hover:before, .hvr-bounce-to-top:focus:before, .hvr-bounce-to-top:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
  

  .btn-light{
    background: #fff !important;
    transition: all 0.4s ease;
    &:hover{
      background: #eee !important;
      width:  103%;
    }
  }
  .btn-light-special{
    background: #fff !important;
    transition: none 0.4s ease;
    &:hover{
      width: 100%;
    }
  }
  .btn-light-special2{
    background: #fff !important;
    transition: none 0.4s ease;
    &:hover{
      width: auto;
    }
  }

  .circle-icon {
    background: #fff;
    color:#2C3D4E;
    padding:5px 7px;
    border-radius: 50%;

   
}
.input-icons h1 { 
  position: absolute; 
} 

.input-icons { 
  width: 100%; 
  margin-bottom: 10px; 
} 

.icon { 
  padding: 10px; 
  min-width: 40px; 
} 

.input-field { 
  width: 100%; 
  padding: 10px; 
  text-align:center; 

} 
