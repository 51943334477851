// Testing SCSS structure

.bg-secondary{
    background: #fed766 !important;
}

.text-secondary{
    color: #fed766 !important;
}

.navbar-dark{
    background-color:rgba(0,0,0,.2) !important;
}


[dir="rtl"] .navbar-toggler{
       float: left;
       border: none;
   }

[dir="ltr"] .navbar-toggler{
    float:right;
    border: none;
}

/* ANIMATED X */
.navbar-toggler>.close {
    display:inline;
}
.navbar-toggler.collapsed>.close, .navbar-toggler:not(.collapsed)>.navbar-toggler-icon {
    display:none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.close-nav{
 color: white;
  width: 22px;
  height: 14px;
}
.collapse:not(.show){
    display: block!important;
}
#navbarToggleExternalContent{
    transition: all 0.35s ease-out;
    background:#253342;
    z-index: 1;
}
#navbarToggleExternalContent.collapse{
    transform: translate(-80%,-100%);
    border-radius: 100%;

}
#navbarToggleExternalContent.collapse.show{
    z-index: 2;
    background:#253342;
    transform: translate(0,0);
    border-radius: 0%;

}
