$theme-colors: (
  "primary": rgba(199,171,134,1),
  "secondary": #253342,
  "danger": #fe4a49,
  "warning": #fed766,
  "light": #e6e6ea,
  "dark": #000
);
$enable-rounded:true;


@import 'ّّ~bootstrap/scss/bootstrap';


a,a:hover{
  text-decoration: none;
}

@media (min-width: 1200px){
.container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1000px;
}
}

// .parallax::-webkit-scrollbar {
//   width: 0px;
//   background: transparent; /* Chrome/Safari/Webkit */
// }

// .parallax {
// scrollbar-width: none; /* Firefox */
// -ms-overflow-style: none;  /* IE 10+ */
// }
