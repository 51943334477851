
@import 'ّّ~bootstrap/scss/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "scss/app.scss";

* {
  direction: rtl;
}

body{
  background: #253342;
  color: #fff;
}


/* Style all font awesome icons */
.fa {
  padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

/* Set a specific color for each brand */

.parallax{
  background:  url(/images/parallax/BG2.png) fixed center,url(/images/parallax/BG3.png)  center, url(/images/parallax/BG0.png)  center, url(/images/parallax/BG4.png) center top;
  background-size: cover;
}
.parallax:before{
  content:"";
  position: absolute;
  height:100%;
  width:100%;
  background:  url(/images/parallax/BG3.png) fixed right bottom no-repeat;
  background-size:cover;
}
.parallax:before{
  content:"";
  position: absolute;
  height:100%;
  width:100%;
  background:  url(/images/parallax/BG1.png) fixed right bottom no-repeat;
  background-size:69%;
}

.landing-carousel-button{
  width: 20%
}

@media (max-width: 769px) {
.landing-carousel-button{
  width: 50%
}
}

.slider-shadow{

  &:before{
    content:"";
     position: absolute;
     left: 0;
     top:0;
     width: 50px;
     height: 100%;
     background: linear-gradient(90deg, #253342 0%, rgba(37,51,66,0) 100%);
    z-index:1;
  }
  &:after{
    content:"";
     position: absolute;
     right: 0;
     top:0;
     width: 50px;
     height: 100%;
     background: linear-gradient(90deg, rgba(37,51,66,0) 0%, #253342 100%);
    z-index:1;
  }
}