/* This changes all the animations globaly */
:root {
    --animate-duration: 3000ms;
    --animation-delay: 0s;
    --animate-delay: 2s;
  }


@keyframes rotate{
    from{
      transform: rotate(0);
    }
    to{
      transform: rotate(360deg);
    }
  }