.ballons {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        height: 100%;
        width: 100%;
        background: url('/assets/images/ballons-color.svg') no-repeat;
    }
}

.lamp {
        content: "";
        position: absolute;
        top: -15px;
        left: -6rem;
        height: 100%;
        width: 100%;
        background: url('/assets/images/lamp.svg') no-repeat;
    }


.fire {
    position: relative;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 4px;
        height: 65px;
        width: 36px;
        background: url('/assets/images/fire.svg') no-repeat;
        background-size: 24px
    }

    &:before {
        left: 8px;
    }

    &:after {
        right: 0;
    }
}

.result {
    position:relative;
    background: url('/assets/images/result.svg') no-repeat;
    background-size: cover;
}




.show{background: rgba(216,216,216,.15)}
div + .show { background: rgba(216,216,216,.15)}
 .black-gradient{
    background: transparent;
    background: linear-gradient(0deg, transparent 45%, rgba(10,10,10,1) 100%);
 }