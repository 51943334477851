.landing-jumbotron{
    position: relative;
    overflow: hidden;
}
.landing-jumbotron:before{
    content:"";
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
}

.shadow-background{
    &:before{
        content:"";
        position:absolute;
        top:0;
        width:100%;
        height:100px;
        opacity: 0.3;
        background: linear-gradient(180deg, #000000 0%, rgba(0,0,0,0) 100%);
    }


}

.ocean-background:before{
    background: url("/images/ocean.png") no-repeat center center; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}
.ocean2-background:before{
    background: url("/images/ocean2.png") no-repeat center center; 
    -webkit-background-size: 106%;
    -moz-background-size: 106%;
    -o-background-size: 106%;
    background-size: 106%;
}
.building-background:before{
    background: url("/images/building.png") no-repeat center center; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}
.shaking-background:before{
    background: url("/images/shaking.png") no-repeat center center; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}

.writing-background:before{
    background: url("/images/writing.png") no-repeat center center; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}
.writing2-background:before{
    background: url("/images/writing2.png") no-repeat center center; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}
@keyframes zoom {
    from{
        transform: scale(1);
    }
    to{
        transform: scale(1.3);
    }
}

.quote-background{
    position: relative;
}
.quote-background:before{
    content:"";
    position: absolute;
    top:0;
    left:30px;
    width: 50px;
    height: 50px;
    background: url("/images/quote.svg") no-repeat center center; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}

@media (max-width: 769px) {
    .quote-background:before{
        background: none;
    }
}
