
@font-face {
    font-family: 'Dubai-Bold';
    src:  url('/webfonts/DubaiW23-Bold.eot') format('embedded-opentype'),  
          url('/webfonts/DubaiW23-Bold.woff') format('woff'), 
          url('/webfonts/DubaiW23-Bold.ttf')  format('truetype'), 
          url('/webfonts/DubaiW23-Bold.svg#Dubai-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Dubai-Medium';
    src:  url('/webfonts/DubaiW23-Medium.eot') format('embedded-opentype'),  
          url('/webfonts/DubaiW23-Medium.woff') format('woff'), 
          url('/webfonts/DubaiW23-Medium.ttf')  format('truetype'), 
          url('/webfonts/DubaiW23-Medium.svg#Dubai-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Dubai-Regular';
    src:  url('/webfonts/DubaiW23-Regular.eot') format('embedded-opentype'),  
          url('/webfonts/DubaiW23-Regular.woff') format('woff'), 
          url('/webfonts/DubaiW23-Regular.ttf')  format('truetype'), 
          url('/webfonts/DubaiW23-Regular.svg#Dubai-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Dubai-Light';
    src:  url('/webfonts/DubaiW23-Light.eot') format('embedded-opentype'),  
          url('/webfonts/DubaiW23-Light.woff') format('woff'), 
          url('/webfonts/DubaiW23-Light.ttf')  format('truetype'), 
          url('/webfonts/DubaiW23-Light.svg#Dubai-Light') format('svg');
    font-weight: normal;
    font-style: normal;
  }

*,h1,h2,h3,h4,h5,h6,p{
    font-family: 'Dubai-medium', sans-serif;
    color:#fff;
}
h1, .h1 {
  font-size: 2.3rem !important;
}
h2, .h2 {
  font-size: 1.8rem !important;
}
h3, .h3{
  font-size: 1.5rem !important;
}
h4, .h4{
font-size: 1.3rem !important;
}
h5, .h5{
font-size: 1rem !important;
}
h6, .h6{
font-size: 0.75rem !important;
}
p, .p{

}
button,span{
  color: #000;
}
.font-weight-bold{
    font-family: 'Dubai-Bold', sans-serif;
}
.font-weight-light{
  font-family: 'Dubai-Light', sans-serif;
}
.fab{
  position: relative;
}
.fa-linkedin:before,.fa-twitter:before,.fa-instagram:before {
  content:"";
}
.fa-linkedin,.fa-twitter,.fa-instagram{
  height: 30px;
  width: 30px;
}

.fa-linkedin{
  background: url('/images/linkedin-icon.svg') no-repeat;
}
.fa-twitter{
  background: url('/images/twitter-icon.svg') no-repeat;
}
.fa-instagram{
  background: url('/images/instagram-icon.svg') no-repeat;
}